#page-contact {

  .section-hero {
    position: relative;
    height: 768px;

    /** Mobile. */
    @media (max-width: 1023px) {
      height: auto;
    }

    .background {
      background-color: #0B213C;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 610px;
        bottom: unset;
      }

      &::after {
        content: '';
        background-image: linear-gradient(to right, #003373, fade-out(#003373, 1));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          background-image: linear-gradient(to top, #003373, fade-out(#003373, 1));
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .5;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 768px;
      padding: 0 0 50px;

      /** Mobile. */
      @media (max-width: 1023px) {
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 180px 0 0;
        height: auto;
      }

      .left {
        width: 345px;
        flex-shrink: 0;
        padding-bottom: 50px;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          padding: 0;
        }

        &-title {
          color: white;
          font-size: 48px;
          font-weight: 300;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
            }
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 44px;
          }
        }

        &-description {
          margin: 40px 0;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            max-width: 325px;
          }

          p {
            color: white;
          }
        }

        &-navigation ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transform: translateX(10px);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateX(0);
            }
          }

          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }

            button {
              color: white;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 170px;
              height: 40px;
              padding-top: 2px;
              border: 1px solid white;
              border-radius: 20px;
              transition-property: border-color, background-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover button,
            &.active button {
              background-color: color(orange);
              border-color: color(orange);
            }
          }
        }
      }

      .right {
        width: 530px;
        flex-shrink: 0;
        margin-left: 135px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1100px) {
          margin-left: 50px;
        }
        @media (max-width: 1023px) {
          width: 100%;
          margin: 140px 0 0;
        }

        &-tabs {
          position: relative;
          width: 100%;
          transition-property: height;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          .tab-content {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 50px 30px 30px;
            border: 1px solid white;
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            transition-property: opacity, visibility;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            /** Mobile. */
            @media (max-width: 1023px) {
              border-color: color(blue);
              top: 0;
              bottom: unset;
            }

            &.active {
              opacity: 1;
              visibility: visible;
              z-index: 2;
              transition-delay: .3s;
            }

            .form {
              &-row,
              &-flex {
                margin-bottom: 25px;
              }

              &-flex {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 30px;

                /** Mobile. */
                @media (max-width: 1023px) {
                  grid-template-columns: repeat(1, 1fr);
                  grid-row-gap: 25px;
                }
              }

              &-submit {
                text-align: right;
                margin-top: 35px;
                padding-right: 20px;

                span {

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    color: color(blue);
                  }
                }
              }

              .field-group {
                width: 100%;

                .label {
                  color: white;
                  font-size: 10px;
                  font-weight: bold;
                  text-transform: uppercase;
                  letter-spacing: 3px;
                  margin-bottom: 10px;

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    color: color(blue);
                  }
                }

                .field {
                  background-color: transparent;
                  color: white;
                  font-size: 15px;
                  font-weight: normal;
                  padding-bottom: 10px;
                  display: block;
                  width: 100%;
                  border: none;
                  border-bottom: 1px solid fade-out(white, .7);
                  transition-property: border-color;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    border-color: fade-out(color(blue), .7);
                  }

                  &::placeholder {
                    color: fade-out(white, .7);
                    font-weight: bold;

                    /** Mobile. */
                    @media (max-width: 1023px) {
                      color: fade-out(color(blue), .7);
                    }
                  }
                }

                textarea {
                  height: 100px;
                  resize: none;
                }

                &[data-type="file"] {
                  position: relative;

                  label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    z-index: 5;
                  }
                  
                  .field {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                  }

                  .box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding-bottom: 10px;
                    border-bottom: 1px solid fade-out(white, .7);

                    span {
                      color: fade-out(white, .7);
                      font-weight: bold;
                    }

                    svg path {
                      fill: white;
                    }
                  }

                  &.selected .box span {
                    color: white;
                    font-weight: normal;
                  }
                }

                &.invalid .field {
                  border-color: red;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-faq {
    padding: 70px 0 145px;

    .headline {
      text-align: center;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      &-title {
        color: color(blue);
        font-size: 36px;
        font-weight: 300;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 24px;
        }
      }
    }

    .accordions {
      margin-top: 100px;
      padding: 0 100px;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      /** Mobile. */
      @media (max-width: 1023px) {
        margin-top: 50px;
        padding: 0;
      }

      .accordion {
        position: relative;
        border-bottom: 1px solid color(orange);

        &-header {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 20px 60px 20px 0;

          span {
            color: color(blue);
            font-weight: 500;
            display: inline-block;
            text-align: left;
            line-height: 1.4;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 16px;
            }
          }

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            transition: transform time(fast) ease(inout);

            path {
              stroke: color(orange);
            }
          }
        }

        &-wrapper {
          height: 0;
          transition: height time(fast) ease(inout);
          overflow: hidden;
          
          &--content {
            color: color(grey);
            padding: 10px 0 30px;
          }
        }

        /** Active. */
        &.active {
          .accordion-header svg {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}