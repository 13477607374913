#page-simulator {
  background-color: #002040;

  .section-hero {
    position: relative;
    height: 768px;

    /** Mobile. */
    @media (max-width: 1023px) {
      height: 610px;
    }

    .background {
      background-color: #002040;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      &::before {
        content: '';
        background-image: linear-gradient(to top, #002040, fade-out(#002040, 1));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
      }

      &::after {
        content: '';
        background-image: linear-gradient(to right, #002040, fade-out(#002040, 1));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .5;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
      width: 700px;
      height: 768px;
      margin: 0 auto;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
        height: 610px;
      }

      &-title {
        color: white;
        font-size: 48px;
        font-weight: 300;
        text-align: center;
        display: block;
        width: 100%;

        /** Mobile. */
        @media (max-width: 1199px) {
          font-size: 44px;
          display: block;
          width: 100%;
        }
        @media (max-width: 1023px) {
          font-size: 24px;
        }
      }

      &-description {
        text-align: center;
        margin-top: 20px;
        width: 100%;
        display: block;

        p {
          color: white;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: .8px;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .section-form {
    position: relative;
    margin: -280px 0 0;
    padding: 0 0 70px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      margin: -200px 0 0;
    }

    .block {
      position: relative;
      width: 700px;
      padding: 35px;
      border: 1px solid white;
      border-radius: 6px;
      margin: 0 auto;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
        padding: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &-title {
        color: color(orange);
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 40px;
      }

      &-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        /** Mobile. */
        @media (max-width: 1023px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .field-group {
          width: 100%;

          .label {
            color: white;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 10px;

            /** Mobile. */
            @media (max-width: 1023px) {
              letter-spacing: 1px;
            }
          }

          .field {
            background-color: transparent;
            color: white;
            font-size: 15px;
            font-weight: normal;
            padding-bottom: 10px;
            display: block;
            width: 100%;
            border: none;
            border-bottom: 1px solid fade-out(white, .7);
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: fade-out(white, .7);
              font-weight: bold;
            }
          }

          textarea {
            height: 100px;
            resize: none;
          }

          &.invalid .field {
            border-color: red;
          }
        }
      }
    }

    .form-submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 50px 0;

      > span {
        color: white;
        font-size: 14px;
        font-weight: normal;
        opacity: .5;
        margin-top: 20px;
      }
    }
  }

  .section-blocks {
    position: relative;
    margin-bottom: 70px;

    &.active {
      padding: 0 0 70px;

      .flex-wrapper {
        display: flex;
      }
    }

    .flex-wrapper {
      display: none;
      align-items: center;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 270px;
        height: 170px;
        z-index: 1;
        border: 1px solid white;
        border-radius: 6px;

        &:not(:last-child) {
          margin-right: 45px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin: 0 0 25px;
          }
        }

        &-title {
          color: color(orange);
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        &-content {
          color: white;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          height: 30px;
          margin-top: 24px;

          span {
            font-size: 16px;
            font-weight: normal;
          }
        }
      }
    }
  }
}