#page-home {
  .section-hero {
    background-color: black;
    position: relative;
    width: 100%;
    z-index: 3;

    .slide {
      position: relative;
      width: 100%;
      height: 768px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 610px;
      }

      &-items {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        .item {
          position: relative;
          width: 100%;
          height: 768px;

          /** Mobile. */
          @media (max-width: 1023px) {
            height: 610px;
          }

          &-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            &::after {
              content: "";
              background-image: linear-gradient(
                to right,
                #003373,
                fade-out(#003373, 1)
              );
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2;

              /** Mobile. */
              @media (max-width: 1023px) {
                background-image: linear-gradient(
                  to top,
                  #003373,
                  fade-out(#003373, 1)
                );
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-wrapper {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;

            .content {
              /** Mobile. */
              @media (max-width: 1023px) {
                text-align: center;
                padding-top: 50px;
              }

              &-description {
                width: 800px;
                margin-bottom: 50px;

                /** Mobile. */
                @media (max-width: 1023px) {
                  width: 100%;
                }

                p {
                  color: white;
                  font-size: 48px;
                  font-weight: 300;
                  line-height: 1.4;

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    font-size: 32px;
                  }
                  @media (max-width: 355px) {
                    font-size: 28px;
                  }

                  strong {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      &-navigation {
        position: absolute;
        right: 0;
        bottom: 100px;
        left: 0;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          bottom: 40px;
        }

        .container {
          display: flex;
          align-items: center;

          /** Mobile. */
          @media (max-width: 1023px) {
            justify-content: center;
          }

          .slide-arrow {
            /** Mobile. */
            @media (max-width: 1023px) {
              display: none;
            }

            svg path {
              fill: white;
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              fill: color(orange);
            }
          }

          .slide-count {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 20px;
            top: -1px;

            span {
              color: white;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .section-buildings {
    position: relative;
    padding: 130px 0 65px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 80px 0 60px;
    }

    .headline {
      text-align: center;
      margin-bottom: 115px;

      /** Mobile. */
      @media (max-width: 1023px) {
        margin-bottom: 40px;
      }

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &-title {
        background-image: linear-gradient(to right, #ea3424, #fec70c);
        font-size: 60px;
        font-weight: 300;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 36px;
        }
      }
    }

    .flex-wrapper {
      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .slide-items {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 1100px) {
          flex-direction: column;
          align-items: center;
        }

        .building {
          /** Mobile. */
          @media (max-width: 1100px) {
            max-width: 100%;

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          /** Desktop. */
          @media (min-width: 1100px) {
            &:not(:last-child) {
              margin-right: 30px;
            }

            &:nth-child(2) {
              margin-top: 65px;
            }
            &:nth-child(3) {
              margin-top: 130px;
            }
          }
        }
      }

      .slide-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        /** Mobile. */
        @media (min-width: 1100px) {
          display: none;
        }

        .owl-dot {
          background-color: color(blue);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          opacity: 0.5;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-right: 15px;
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }

    .more {
      text-align: center;
      margin-top: 50px;
    }
  }

  .section-about {
    position: relative;
    padding: 100px 0 175px;

    /** Mobile. */
    @media (max-width: 355px) {
      padding: 100px 0 120px;
    }

    .background {
      position: absolute;
      right: 0;
      bottom: -100px;
      left: 0;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        bottom: 0;
      }

      img {
        width: 100%;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1100px) {
        flex-wrap: wrap;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 510px;
        flex-shrink: 0;
        margin-right: 95px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
          }
        }

        /** Mobile. */
        @media (max-width: 1100px) {
          width: 100%;
          margin: 0 0 35px;
        }
        @media (max-width: 767px) {
          align-items: flex-start;
        }

        span {
          color: color(grey);
          font-size: 48px;
          font-weight: 300;
          margin-bottom: 10px;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 32px;
          }
          @media (max-width: 355px) {
            font-size: 28px;
          }

          &:nth-child(1) {
            margin-left: -120px;

            /** Mobile. */
            @media (max-width: 1023px) {
              margin: 5px 0;
            }
          }

          &:nth-child(3) {
            background-image: linear-gradient(to right, #ea3424, #fec70c);
            font-size: 72px;
            font-weight: 200;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 0;

            /** Mobile. */
            @media (max-width: 355px) {
              font-size: 64px;
            }
          }
        }
      }

      .content {
        width: 485px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1100px) {
          width: 100%;
          text-align: center;
        }

        &-description {
          margin-bottom: 35px;

          p {
            color: color(grey);
          }
        }
      }
    }
  }
}
