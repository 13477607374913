/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/owl/owl.carousel.min.css";
@import "../vendor/fancybox/jquery.fancybox.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
  background-image: linear-gradient(to right, #e3e5f1 10%, white);
  font-size: 16px;
  font-family: "Gilroy";

  /** Mobile. */
  @media (max-width: 1100px) {
    background-color: white;
    background-image: unset;
  }

  &::before {
    content: "";
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }

  &.menu-open {
    overflow: hidden;
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

/** @import: Globals. */
@import "globals/header";
@import "globals/footer";
@import "globals/menu";
@import "globals/cookies";

/** @import: Elements. */
@import "elements/general";
@import "elements/button";
@import "elements/building";
@import "elements/progress-screen";

/** @import: Modals. */
@import "modals/general";
@import "modals/ligamos";
@import "modals/cta-building";

/** @import: Sections. */
// @import "sections/{{section_name}}";

/** @import: Pages. */
@import "pages/home";
@import "pages/about";
@import "pages/contact";
@import "pages/buildings";
@import "pages/building";
@import "pages/simulator";
@import "pages/terms";

/** @import: Pages [Mobile]. */
// @import "pages/mobile/{{page_name}}";
