
// Gilroy
// ---------

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
       url('../assets/fonts/Gilroy-UltraLight.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'),
       url('../assets/fonts/Gilroy-Light.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
       url('../assets/fonts/Gilroy-Regular.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
       url('../assets/fonts/Gilroy-Medium.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
       url('../assets/fonts/Gilroy-Semibold.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
       url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}