#page-terms {
  /** Mobile. */
  @media (max-width: 1199px) {
    background-color: color(blue);
    margin-bottom: 75px;
  }

  .section-content {
    padding: 200px 0 100px;

    /** Mobile. */
    @media (max-width: 1199px) {
      padding: 170px 0 75px;
    }

    .section-title {
      color: color(blue);
      font-size: 48px;
      font-weight: 300;
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 40px;

      /** Mobile. */
      @media (max-width: 1199px) {
        color: white;
        font-size: 28px;
        line-height: 1.2;
      }
    }

    .content {
      width: 970px;
      margin: 0 auto;

      /** Mobile. */
      @media (max-width: 1199px) {
        color: white;
        width: 100%;
      }

      a {
        color: color(blue);
        font-weight: bold;
      }

      ul li::before {
        /** Mobile. */
        @media (max-width: 1199px) {
          background-color: white;
        }
      }
    }
  }
}
