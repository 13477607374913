.modal-cta-building {
  background-color: white;

  .close {
    position: absolute;
    top: 50px;
    right: 23px;
    z-index: 3;

    svg path {
      fill: black;
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    left: 25px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      color: black;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.3;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
    }

    &-form {
      width: 100%;
      max-width: 300px;
      margin-top: 30px;

      .form {
        padding: 0;

        .field-group {
          &:not(:last-child) {
            margin-bottom: 35px;
          }

          .field {
            background-color: transparent;
            color: color(blue);
            font-size: 16px;
            font-weight: normal;
            padding-bottom: 10px;
            width: 100%;
            display: block;
            border: none;
            border-bottom: 1px solid fade-out(color(blue), .7);
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: color(blue);
              font-weight: bold;
              position: relative;
              top: 2px;
            }
          }

          &.invalid .field {
            border-color: red;
          }
        }

        .terms-option {
          margin: -15px 0 20px;

          button {
            background-color: transparent;
            padding-left: 25px;
            border: none;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 1px;
              left: 0;
              width: 12px;
              height: 12px;
              border: 1px solid color(blue);
              transition-property: background-color, border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;
            }

            span {
              color: color(blue);
              font-size: 9px;
              text-align: left;
              line-height: 1.4;
              font-weight: normal;
              display: inline-block;

              a {
                color: color(blue);
                font-weight: bold;
              }
            }
          }

          &.active button::before {
            background-color: color(orange);
            border-color: color(orange);
          }
        }

        .form-submit {
          button {
            background-image: linear-gradient(to right, #FEC70C, #EA3424);
            color: white;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 3px;
            width: 100%;
            height: 60px;
          }
        }
      }
    }
  }
}