#main-footer {
  position: relative;
  padding: 0 0 40px;
  z-index: 2;

  /** Appear. */
  @media (min-width: 1200px) {
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    &.appear {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /** Mobile. */
  @media (max-width: 1023px) {
    padding: 0 0 100px;
  }

  .flex-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;

    /** Mobile. */
    @media (max-width: 1023px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 30px;
    }

    .block {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.4);

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        min-height: 200px;
        padding: 10px 0;
        z-index: 2;

        &--title {
          color: white;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 2px;
          line-height: 1.6;
        }

        &--subtitle {
          color: white;
          font-size: 16px;
          font-weight: 500;
          margin-top: 15px;
          line-height: 1.4;
        }

        &--description {
          margin: 3px 0 15px;

          p {
            color: #9EC3DC;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.6;
          }
        }

        &--social {
          margin-top: 35px;

          ul {
            display: flex;
            align-items: center;

            li {
              &:not(:last-child) {
                margin-right: 35px;
              }

              a {
                background-color: transparent;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border: 2px solid color(orange);
                transition-property: background-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                svg path {
                  fill: white;
                }

                &:hover {
                  background-color: color(orange);
                }
              }
            }
          }
        }

        &--cta {
          a {
            color: color(orange);
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:hover {
              color: white;
            }
          }
        }

        &--options {
          margin: 30px 0 25px;

          .option {
            display: flex;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            &:nth-child(2) {
              a {
                padding-left: 2px;

                span {
                  margin-left: 12px;
                }
              }
            }

            > span {
              color: white;
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              width: 90px;
              flex-shrink: 0;
            }

            a {
              display: flex;
              align-items: center;

              svg path {
                fill: #9EC3DC;
              }

              span {
                color: white;
                font-size: 18px;
                font-weight: 500;
                display: inline-block;
                margin-left: 10px;
                position: relative;
                bottom: -1px;
                transition-property: color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }

              &:hover span {
                color: #9EC3DC;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    /** Mobile. */
    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    > * {
      width: 335px;
    }

    span {
      color: #708397;
      font-size: 14px;
      font-weight: 500;

      /** Mobile. */
      @media (max-width: 1023px) {
       display: block;
       text-align: center;
       line-height: 1.4;
       width: 190px;
       margin: 0 0 20px;
      }
    }

    .terms {
      text-align: center;

      a {
        color: #708397;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid #708397;
      }
    }

    .signature {
      text-align: right;

      /** Mobile. */
      @media (max-width: 1023px) {
        text-align: center;
        margin-top: 20px;
      }

      a {
        display: inline-block;
        
        svg {
          transform: scale(1.5);
          transform-origin: 100% 50%;

          path {
            fill: color(blue);
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }

        &:hover svg path {
          fill: color(orange);
        }
      }
    }
  }
}