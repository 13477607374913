#main-menu {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  .logo {
    background-image: linear-gradient(to bottom, #FCFDFD, #BDCDDA);
    position: absolute;
    top: 30px;
    left: 25px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 20px 34.641px 80px 0px rgba(31, 34, 47, 0.2);
    z-index: 2;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 40px;
      }
    }
  }

  .close {
    position: absolute;
    top: 55px;
    right: 28px;
    z-index: 5;

    svg path {
      fill: color(blue);
    }
  }

  .navigation {
    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    transform: translate(-50%, -50%);

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      li {
        &:not(:last-child) {
          margin-bottom: 25px;

          /** Mobile. */
          @media (max-width: 355px) {
            margin-bottom: 15px;
          }
        }

        a {
          color: color(blue);
          font-size: 28px;
          font-weight: normal;
          white-space: nowrap;

          /** Mobile. */
          @media (max-width: 355px) {
            font-size: 22px;
          }
        }
      }
    }
  }

  .social {
    ul {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 40px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border: 2px solid color(orange);
          border-radius: 50%;

          svg path {
            fill: color(blue);
          }
        }
      }
    }
  }

  .copyright {
    color: #708397;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    width: 190px;
    margin-top: 25px;
  }

  .signature {
    margin-top: 20px;

    a {
      display: inline-block;
      
      svg {
        transform: scale(1.5);
        transform-origin: 100% 50%;

        path {
          fill: color(blue);
        }
      }
    }
  }
}