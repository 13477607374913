#page-buildings {

  .section-hero {
    position: relative;
    width: 100%;
    z-index: 1;

    .background {
      background-color: #0B213C;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      &::after {
        content: '';
        background-image: linear-gradient(to right, #003373, fade-out(#003373, 1));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          background-image: linear-gradient(to top, #003373, fade-out(#003373, 1));
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .5;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 768px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 610px;
      }

      &-title {
        color: white;
        font-size: 48px;
        font-weight: 300;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 44px;
        }
      }
    }
  }

  .section-buildings {
    position: relative;
    margin-top: -170px;
    padding: 0 0 115px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 0 0 80px;
    }

    .listing {
      &-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 50px;

        /** Mobile. */
        @media (max-width: 1023px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .building {

          /** Mobile. */
          @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      &-pagination {
        margin-top: 100px;

        .wp-pagenavi {
          display: flex;
          align-items: center;
          justify-content: center;

          span,
          a {
            color: #B5B8C8;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;

            &:not(:last-child) {
              margin-right: 30px;
            }
          }

          span {
            color: #001F3F;
          }

          a {
            transition: color time(default) ease(inout);

            &:hover {
              color: #001F3F;
            }
          }
        }
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 100px;
    }
  }
}