#page-about {
  .section-hero {
    position: relative;
    height: 768px;

    /** Mobile. */
    @media (max-width: 1023px) {
      height: 610px;
    }

    .background {
      background-color: #0b213c;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      &::after {
        content: "";
        background-image: linear-gradient(
          to right,
          #003373,
          fade-out(#003373, 1)
        );
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          background-image: linear-gradient(
            to top,
            #003373,
            fade-out(#003373, 1)
          );
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 768px;
      padding: 0 100px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 610px;
        padding: 50px 0 0;
      }

      &-title {
        color: white;
        font-size: 48px;
        font-weight: 300;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1199px) {
          font-size: 44px;
          text-align: center;
          display: block;
          width: 100%;
        }
      }

      &-description {
        width: 480px;
        margin-top: 40px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1199px) {
          width: 100%;
          text-align: center;
        }
        @media (max-width: 767px) {
          text-align: left;
        }

        p {
          color: white;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .section-about {
    position: relative;
    padding: 125px 0 85px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 100px 0 0;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .content {
      padding: 0 100px;
      margin-bottom: 80px;

      /** Mobile. */
      @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
      }

      &-title {
        background-image: linear-gradient(to right, #ea3424, #fec70c);
        font-size: 48px;
        font-weight: 300;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-bottom: 50px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 36px;
          text-align: center;
          margin-bottom: 35px;
        }
      }

      &-description {
        width: 440px;

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        p {
          color: color(grey);
        }
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;

      /** Mobile. */
      @media (max-width: 1100px) {
        flex-direction: column;
        margin-top: 75px;
      }

      .image {
        width: 570px;
        height: 360px;
        flex-shrink: 0;
        box-shadow: 0px 60px 120px 0px rgba(0, 38, 69, 0.3);

        /** Mobile. */
        @media (max-width: 1100px) {
          width: 100%;
          height: 250px;
          box-shadow: 0px 60px 120px 0px rgba(0, 38, 69, 0.1);
        }

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transform: translateX(-10px);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateX(0);
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .wrapper {
        width: 455px;
        margin-left: 70px;

        /** Mobile. */
        @media (max-width: 1100px) {
          width: 100%;
          margin: 25px 0 0;
        }

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateY(0);
          }
        }

        &-title {
          background-image: linear-gradient(to right, #ea3424, #fec70c);
          font-size: 34px;
          font-weight: 300;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
          margin-bottom: 20px;

          /** Mobile. */
          @media (max-width: 1100px) {
            font-size: 24px;
          }
        }

        &-description {
          p {
            color: color(grey);
          }
        }
      }
    }
  }

  .section-blocks {
    padding: 75px 0;

    .wrapper {
      &-item {
        width: 860px;
        margin: 0 auto;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 100px;
        }

        &--title {
          color: color(blue);
          font-size: 48px;
          font-weight: normal;
        }

        &--description {
          margin-top: 40px;
        }
      }
    }
  }

  .section-numbers {
    padding: 100px 0 30px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0 30px;
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      /** Mobile. */
      @media (max-width: 1023px) {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 20px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /** Mobile. */
        @media (max-width: 1023px) {
          padding: 30px 0;
          border: 2px solid #c9d8e2;
          border-radius: 5px;
        }

        &-number {
          background-image: linear-gradient(to right, #ea3424, #fec70c);
          font-size: 48px;
          font-weight: 300;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          height: 70px;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 38px;
            height: 50px;
          }
          @media (max-width: 355px) {
            font-size: 30px;
            height: 40px;
          }
        }

        &-title {
          color: color(blue);
          font-size: 24px;
          font-weight: 300;
          text-align: center;
          line-height: 1.3;
          flex-grow: 1;

          @media (max-width: 1023px) {
            font-size: 21px;
          }
        }
      }
    }
  }

  .section-testimonials {
    padding: 50px 0 30px;

    /** Appear. */
    @media (min-width: 1200px) {
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);

      &.appear {
        opacity: 1;
        transform: translateY(0);
      }
    }

    /** Mobile. */
    @media (max-width: 1023px) {
      display: none;
    }

    .wrapper {
      padding: 0 100px;

      .slide {
        position: relative;
        padding: 65px 50px 55px;

        &-border {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 4;
          pointer-events: none;

          &:nth-child(1) {
            &::before,
            &::after {
              content: "";
              background-image: linear-gradient(to right, #ea3424, #fec70c);
              position: absolute;
              right: 0;
              left: 0;
              height: 2px;
            }

            &::before {
              top: 0;
            }

            &::after {
              bottom: 0;
            }
          }

          &:nth-child(2) {
            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              width: 2px;
            }

            &::before {
              background-color: #ea3424;
              left: 0;
            }

            &::after {
              background-color: #fec70c;
              right: 0;
            }
          }
        }

        &-title {
          color: color(blue);
          font-size: 36px;
          font-weight: 300;
          text-align: center;
          margin-bottom: 50px;
        }

        &-items {
          margin-bottom: 40px;

          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            &-description {
              width: 587px;
              margin-bottom: 15px;

              p {
                color: color(grey);
              }
            }

            &-title {
              color: color(grey);
              font-size: 16px;
              font-weight: 600;
            }
          }
        }

        &-navigation {
          display: flex;
          align-items: center;
          justify-content: center;

          .slide-arrow {
            svg path {
              fill: #a5c5e0;
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              fill: color(blue);
            }
          }

          .slide-count {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 20px;
            top: -1px;

            span {
              color: color(blue);
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .section-buildings {
    padding: 100px 0 140px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0 100px;
    }

    .headline {
      text-align: center;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &-title {
        background-image: linear-gradient(to right, #ea3424, #fec70c);
        font-size: 48px;
        font-weight: 300;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 28px;
          line-height: 1.3;
        }
      }
    }

    .navigation {
      margin-top: 70px;
      display: flex;
      align-items: center;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      /** Mobile. */
      @media (max-width: 1199px) {
        display: none;
      }

      span {
        background-image: linear-gradient(to right, #ea3424, #fec70c);
        font-size: 22px;
        font-weight: bold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-right: 33px;
        flex-shrink: 0;
      }

      ul {
        display: flex;
        align-items: center;
        flex-grow: 1;

        li {
          flex-grow: 1;
          text-align: center;

          button {
            color: #b2b2b2;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            transition: color time(default) ease(inout);
          }

          &.active button,
          &:hover button {
            color: #052340;
          }
        }
      }
    }

    .slide {
      position: relative;
      margin-top: 40px;

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &-items {
        /** Desktop. */
        @media (min-width: 1200px) {
          .owl-item {
            background-color: #002040;
            pointer-events: none;

            &.center {
              pointer-events: initial;

              .item {
                opacity: 1;

                &-content {
                  opacity: 1;
                }
              }
            }
          }
        }

        .item {
          position: relative;
          height: 510px;
          overflow: hidden;
          transition: opacity time(default) ease(inout);
          opacity: 0.2;

          /** Mobile. */
          @media (max-width: 1199px) {
            opacity: 1;
            width: 265px;
            height: auto;
          }

          &-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            /** Mobile. */
            @media (max-width: 1199px) {
              position: relative;
              top: unset;
              right: unset;
              bottom: unset;
              left: unset;
              width: 100%;
              height: 300px;
            }

            &::after {
              content: "";
              background-image: linear-gradient(
                to top,
                #002040 5%,
                fade-out(#002040, 1)
              );
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2;
              opacity: 0.9;

              /** Mobile. */
              @media (max-width: 1199px) {
                display: none;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-content {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0 30px 30px;
            z-index: 2;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            /** Mobile. */
            @media (max-width: 1199px) {
              opacity: 1;
              position: relative;
              top: unset;
              right: unset;
              bottom: unset;
              left: unset;
              padding: 10px 0 0;
            }

            &--status {
              color: white;
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 1px;

              /** Mobile. */
              @media (max-width: 1199px) {
                color: color(blue);
                font-size: 14px;
              }
            }

            &--title {
              color: white;
              font-size: 24px;
              font-weight: 500;
              margin: 20px 0 25px;

              /** Mobile. */
              @media (max-width: 1199px) {
                color: color(blue);
                margin: 10px 0;
              }
            }

            &--location {
              display: flex;
              align-items: center;

              svg path {
                fill: color(orange);
              }

              span {
                color: white;
                font-size: 16px;
                font-weight: 600;
                display: inline-block;
                margin-left: 15px;
                position: relative;
                bottom: -1px;

                /** Mobile. */
                @media (max-width: 1199px) {
                  color: color(blue);
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      &-icon {
        position: relative;
        width: 100%;
        text-align: center;
        margin: 30px 0 0;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.disable {
          opacity: 0;
          transform: translateX(-10px);
        }
      }

      &-arrows {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 548px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1199px) {
          display: none;
        }

        .arrow {
          background-color: color(blue);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
          height: 45px;
          pointer-events: initial;

          svg path {
            fill: white;
          }
        }
      }
    }
  }
}
