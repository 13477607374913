.building {
  position: relative;
  width: 370px;
  height: 495px;

  /** Mobile. */
  @media (max-width: 355px) {
    width: 280px;
    height: 380px;
  }

  &::before {
    content: '';
    background-color: red;
    position: absolute;
    top: 125px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 5px;
    transition-property: box-shadow;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
  }

  &-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  &-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    &::after {
      content: '';
      background-image: linear-gradient(to top, #0A203B 10%, fade-out(#0A203B, 1));
      position: absolute;
      top: 140px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-arrow {
    position: absolute;
    right: 40px;
    bottom: 35px;
    z-index: 4;
    opacity: 0;
    transform: translateX(-10px);
    transition-property: opacity, transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    svg path {
      fill: color(orange);
    }
  }

  &-content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    padding: 0 30px 35px;

    /** Mobile. */
    @media (max-width: 355px) {
      padding: 0 15px 20px;
    }

    &--status {
      color: white;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: inline-block;
      position: relative;
      padding: 0 0 7px;

      /** Mobile. */
      @media (max-width: 355px) {
        font-size: 10px;
      }

      &::after {
        content: '';
        background-image: linear-gradient(to right, #FEC70C, #EA3424);
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
      }
    }

    &--title {
      color: white;
      font-size: 32px;
      font-weight: 500;
      line-height: 1.3;
      padding-right: 80px;
      margin: 20px 0 30px;

      /** Mobile. */
      @media (max-width: 355px) {
        font-size: 24px;
      }
    }

    &--location,
    &--bedrooms {
      display: flex;
      align-items: center;
      padding-left: 3px;

      svg path {
        fill: color(orange);
      }

      span {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;

        /** Mobile. */
        @media (max-width: 355px) {
          font-size: 12px;
        }
      }
    }

    &--bedrooms {
      padding-left: 0;
      margin-top: 20px;

      span {
        margin-left: 10px;
      }
    }
  }

  /** Desktop. */
  @media (min-width: 1100px) {
    &:hover {
      &::before {
        box-shadow: 0px 60px 120px 0px rgba(0, 29, 64, 0.51);
      }

      .building-arrow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}