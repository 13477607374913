.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 60px;

  &::before {
    content: '';
    background-image: linear-gradient(to bottom right, #FCB60F, #ED4920);
    position: absolute;
    top: 0;
    right: -19px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
    transition-property: width;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
  }
  
  span {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 38px;
    position: relative;
    bottom: -1px;
    z-index: 2;
    transition-property: color;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
  }

  svg {
    position: relative;
    z-index: 2;
    transform: translateX(0);
    transition-property: opacity, transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    path {
      fill: white;
    }

    &:nth-child(3) {
      position: absolute;
      top: 22px;
      right: 0;
      opacity: 0;
      transform: translateX(-10px);
    }
  }

  /** Variations. */
  &-white {
    span {
      color: white;
    }
  }

  &-blue {
    span {
      color: color(blue);
    }

    @media (min-width: 1100px) {
      &:hover span {
        color: white;
        transition-delay: .1s;
      }
    }
  }

  /** Desktop animation. */
  @media (min-width: 1100px) {
    &:hover {
      &::before {
        width: calc(100% + 50px);
      }

      svg {
        transform: translateX(20px);
        opacity: 0;

        &:nth-child(3) {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}