#page-building {
  .cta-building-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 33.33%;
    z-index: 35;

    /** Desktop. */
    @media (min-width: 1024px) {
      display: none;
    }

    button {
      background-image: linear-gradient(to right, #fec70c, #ea3424);
      color: white;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      position: relative;
      width: 100%;
      height: 60px;
      padding-top: 2px;
    }
  }

  .section-hero {
    position: relative;
    height: 768px;

    /** Mobile. */
    @media (max-width: 1023px) {
      height: 100vh;
      min-height: 750px;
    }

    .background {
      background-color: #0b213c;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      &::after {
        content: "";
        background-image: linear-gradient(
          to right,
          #003373,
          fade-out(#003373, 1)
        );
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          background-image: linear-gradient(
            to top,
            #003373,
            fade-out(#003373, 1)
          );
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 768px;
      padding: 0 100px 80px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 100vh;
        min-height: 750px;
        padding: 70px 0 0;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      @media (max-width: 767px) {
        align-items: center;
        text-align: left;
      }

      .content {
        width: 480px;
        flex-shrink: 0;

        /** Mobile. */
        @media (max-width: 767px) {
          width: 100%;
        }

        &-status {
          color: white;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 3px;
          display: inline-block;
          position: relative;
          padding: 0 0 7px;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transform: translateY(10px);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /** Mobile. */
          @media (max-width: 355px) {
            font-size: 10px;
          }

          &::after {
            content: "";
            background-image: linear-gradient(to right, #fec70c, #ea3424);
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 2px;
          }
        }

        &-title {
          color: white;
          font-size: 60px;
          font-weight: 300;
          line-height: 1.1;
          margin-top: 25px;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transform: translateY(10px);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 44px;
            margin-top: 15px;
          }
          @media (max-width: 355px) {
            font-size: 32px;
          }
        }

        &-description {
          margin-top: 35px;
          padding-right: 35px;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            padding: 0;
            margin-top: 15px;
          }

          p {
            color: white;

            /** Mobile. */
            @media (max-width: 355px) {
              font-size: 14px;
            }
          }
        }

        &-listing {
          margin-top: 45px;

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transform: translateY(10px);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            margin-top: 25px;
          }

          .item {
            display: flex;
            align-items: center;

            /** Mobile. */
            @media (max-width: 1023px) {
              justify-content: center;
            }
            @media (max-width: 767px) {
              justify-content: flex-start;
            }

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            &-icon {
              position: relative;
              width: 20px;
              text-align: center;
              margin-right: 10px;
              bottom: -1px;

              svg path {
                fill: color(orange);
              }
            }

            &-title {
              color: white;
              font-size: 16px;
              font-weight: 600;

              /** Mobile. */
              @media (max-width: 355px) {
                font-size: 12px;
              }
            }
          }
        }
      }

      .cta {
        width: 330px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 40px 80px 0px rgba(2, 54, 89, 0.64);

        /** Appear. */
        @media (min-width: 1200px) {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
          }
        }

        &-title {
          background-color: #ee4e1f;
          color: white;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.2;
          height: 70px;
        }

        &-form {
          background-color: fade-out(white, 0.2);
          padding: 30px 30px;

          .form {
            .field-group {
              &:not(:last-child) {
                margin-bottom: 35px;
              }

              .field {
                background-color: transparent;
                color: color(blue);
                font-size: 16px;
                font-weight: normal;
                padding-bottom: 10px;
                width: 100%;
                display: block;
                border: none;
                border-bottom: 1px solid fade-out(color(blue), 0.7);
                transition-property: border-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                &::placeholder {
                  color: color(blue);
                  font-weight: bold;
                }
              }

              &.invalid .field {
                border-color: red;
              }
            }

            .terms-option {
              margin: -15px 0 20px;

              button {
                background-color: transparent;
                padding-left: 25px;
                border: none;
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: 1px;
                  left: 0;
                  width: 12px;
                  height: 12px;
                  border: 1px solid color(blue);
                  transition-property: background-color, border-color;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);
                  z-index: 1;
                }

                span {
                  color: color(blue);
                  font-size: 9px;
                  text-align: left;
                  line-height: 1.4;
                  font-weight: normal;
                  display: inline-block;

                  a {
                    color: color(blue);
                    font-weight: bold;
                  }
                }
              }

              &.active button::before {
                background-color: color(orange);
                border-color: color(orange);
              }
            }

            .form-submit {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .section-about {
    position: relative;
    padding: 260px 0 45px;
    z-index: 1;

    /** Mobile. */
    @media (max-width: 1100px) {
      padding: 200px 0 50px;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1100px) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .content {
        width: 455px;
        margin-right: 100px;
        padding-top: 55px;
        flex-shrink: 0;

        /** Mobile. */
        @media (max-width: 1100px) {
          max-width: 100%;
          padding: 0;
          margin: 0 0 40px;
        }

        &-title {
          background-image: linear-gradient(to right, #ea3424, #fec70c);
          font-size: 48px;
          font-weight: 300;
          line-height: 1.2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;

          /** Mobile. */
          @media (max-width: 1100px) {
            font-size: 36px;
            text-align: center;
            padding: 0 30px;

            br {
              display: none;
            }
          }
          @media (max-width: 355px) {
            font-size: 28px;
          }
        }
      }

      .images {
        .image {
          position: relative;
          width: 500px;
          height: 350px;
          box-shadow: 0px 60px 120px 0px rgba(0, 38, 69, 0.5);

          /** Mobile. */
          @media (max-width: 767px) {
            width: 100%;
            height: 250px;
          }

          &:nth-child(1) {
            z-index: 1;
          }

          &:nth-child(2) {
            margin: -60px 0 0 -130px;
            z-index: 2;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .section-features {
    position: relative;
    padding: 100px 0 50px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0 50px;
    }

    .container {
      /** Mobile. */
      @media (max-width: 767px) {
        padding: 0 0 0 25px;
      }
    }

    .headline {
      text-align: center;

      &-title {
        color: color(blue);
        font-size: 36px;
        font-weight: 300;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 32px;
        }
        @media (max-width: 767px) {
          padding-right: 25px;
        }
      }
    }

    .slide {
      width: 640px;
      margin: 80px auto 0;

      /** Mobile. */
      @media (max-width: 767px) {
        width: 100%;
        margin: 50px auto 0;
      }

      &-items {
        .item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .block {
            width: 33.33%;
            padding: 0 15px;
            margin-bottom: 55px;

            /** Mobile. */
            @media (max-width: 1100px) {
              border: 1px solid #677883;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              width: 130px;
              height: 170px;
              margin: 0;
            }

            &-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 60px;

              img {
                width: auto;
              }
            }

            &-title {
              color: color(blue);
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              line-height: 1.2;
              margin-top: 15px;
            }
          }
        }
      }

      &-icon {
        position: relative;
        width: 100%;
        text-align: center;
        margin: 30px 0 0;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &.disable {
          opacity: 0;
          transform: translateX(-10px);
        }
      }

      &-navigation {
        display: flex;
        align-items: center;
        justify-content: center;

        /** Mobile. */
        @media (max-width: 1100px) {
          display: none;
        }

        .slide-arrow {
          opacity: 0.4;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          svg path {
            fill: color(blue);
          }

          &:hover {
            opacity: 1;
          }
        }

        .slide-count {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 20px;
          top: -1px;

          span {
            color: color(blue);
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .section-video {
    position: relative;
    padding: 50px 0 130px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 30px 0;
    }

    .video {
      background-color: black;
      position: relative;
      width: 970px;
      height: 435px;
      margin: 0 auto;
      box-shadow: 0px 34px 81px 0px rgba(0, 0, 0, 0.47);

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
        height: 380px;
      }
      @media (max-width: 767px) {
        height: 220px;
      }

      &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.4;

          /** Mobile. */
          @media (max-width: 1023px) {
            opacity: 0.7;
          }
        }
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        padding-left: 4px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;

        svg path {
          fill: white;
        }
      }
    }
  }

  .section-blocks {
    padding: 0 0 65px;

    /** Desktop. */
    @media (min-width: 1100px) {
      display: none;
    }

    .listing {
      position: relative;

      &-gallery {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
      }

      &-items {
        /** Desktop. */
        @media (min-width: 1024px) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 15px;
        }

        .item {
          position: relative;
          width: 100%;
          height: 160px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 4;
          }

          &-background {
            background-color: black;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 1;
            }
          }

          &-content {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            transform: translateY(-50%);
            text-align: center;
            z-index: 2;

            &--title {
              color: white;
              font-size: 28px;
              font-weight: 300;

              /** Mobile. */
              @media (max-width: 355px) {
                font-size: 22px;
              }
            }

            &--subtitle {
              color: white;
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              text-align: center;
              line-height: 1.4;
              letter-spacing: 4px;
              display: block;
              width: 100%;
              margin-top: 10px;
              padding: 0 20px;
            }
          }
        }
      }
    }
  }

  .section-photos {
    position: relative;
    padding: 80px 0;
    z-index: 2;

    &::after {
      content: "";
      background-color: #1f4574;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50vw;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .slide {
      position: relative;
      width: 770px;
      margin: 0 auto;

      &::before {
        content: "";
        background-color: black;
        position: absolute;
        top: 66px;
        right: 0;
        left: 0;
        height: 435px;
        box-shadow: 0px 60px 120px 0px rgba(0, 38, 69, 0.5);
        z-index: 1;
      }

      &-fancybox {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
      }

      &-headline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--title {
          color: color(blue);
          font-size: 36px;
          font-weight: 300;
        }

        &--fullscreen {
          a {
            display: flex;
            align-items: center;

            span {
              color: white;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 3px;
              position: relative;
              bottom: -2px;
              margin-right: 20px;
            }

            svg path {
              fill: white;
            }
          }
        }
      }

      &-items {
        position: relative;
        margin-top: 30px;
        z-index: 2;

        .item {
          position: relative;

          &-image {
            position: relative;
            width: 100%;
            height: 435px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-title {
            color: black;
            font-size: 16px;
            font-weight: 500;
            margin-top: 25px;
          }
        }
      }

      &-tabs {
        margin-top: 20px;

        .item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;

          &::before {
            content: "";
            background-image: linear-gradient(
              to bottom right,
              #fdc60c,
              #dc3326
            );
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &-image {
            position: relative;
            width: 100px;
            height: 65px;
            margin: 0 auto;
            z-index: 2;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .owl-item.center .item,
        .owl-item .item:hover {
          &::before {
            opacity: 1;
          }
        }
      }

      &-navigation {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: -17px;
        z-index: 3;

        .slide-arrow {
          opacity: 0.4;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          svg path {
            fill: white;
          }

          &:hover {
            opacity: 1;
          }
        }

        .slide-count {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 20px;
          top: -1px;

          span {
            color: white;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .section-plants {
    position: relative;
    padding: 80px 0;
    z-index: 2;

    &::after {
      content: "";
      background-color: #1f4574;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50vw;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .slide {
      position: relative;
      width: 770px;
      margin: 0 auto;

      &::before {
        content: "";
        background-color: black;
        position: absolute;
        top: 66px;
        right: 0;
        left: 0;
        height: 435px;
        box-shadow: 0px 60px 120px 0px rgba(0, 38, 69, 0.5);
        z-index: 1;
      }

      &-fancybox {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
      }

      &-headline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--title {
          color: white;
          font-size: 36px;
          font-weight: 300;
        }

        &--fullscreen {
          a {
            display: flex;
            align-items: center;

            span {
              color: color(blue);
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 3px;
              position: relative;
              bottom: -2px;
              margin-right: 20px;
            }

            svg path {
              fill: color(blue);
            }
          }
        }
      }

      &-items {
        position: relative;
        margin-top: 30px;
        z-index: 2;

        .item {
          position: relative;

          &-image {
            background-color: white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 435px;

            img {
              width: auto;
              max-width: 95%;
              max-height: 95%;
            }
          }

          &-title {
            color: white;
            font-size: 16px;
            font-weight: 500;
            margin-top: 25px;
          }
        }
      }

      &-tabs {
        margin-top: 20px;

        .item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;

          &::before {
            content: "";
            background-image: linear-gradient(
              to bottom right,
              #fdc60c,
              #dc3326
            );
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &-image {
            position: relative;
            width: 100px;
            height: 65px;
            margin: 0 auto;
            z-index: 2;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .owl-item.center .item,
        .owl-item .item:hover {
          &::before {
            opacity: 1;
          }
        }
      }

      &-navigation {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: -17px;
        z-index: 3;

        .slide-arrow {
          opacity: 0.4;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          svg path {
            fill: color(blue);
          }

          &:hover {
            opacity: 1;
          }
        }

        .slide-count {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 20px;
          top: -1px;

          span {
            color: color(blue);
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .section-floors {
    position: relative;
    padding: 140px 0 40px;
    z-index: 2;

    .headline {
      text-align: center;

      &-title {
        color: color(blue);
        font-size: 36px;
        font-weight: 300;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;

      .tabs {
        position: relative;
        width: 525px;
        height: 400px;
        flex-shrink: 0;
        margin-right: 40px;

        .tab-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          z-index: 1;
          transition: opacity time(default) ease(inout),
            visibility time(default) ease(inout);

          &.active {
            opacity: 1;
            visibility: visible;
            z-index: 2;
          }

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .slide {
        background-color: white;
        flex-shrink: 0;
        padding: 35px;
        display: flex;
        align-items: center;

        &-items {
          width: 552px;

          .item {
            display: flex;
            align-items: center;
            height: 330px;

            &-image {
              width: 500px;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            &-number {
              color: color(grey);
              font-size: 24px;
              font-weight: 600;
              margin-left: 25px;
            }
          }
        }

        &-navigation {
          position: relative;
          pointer-events: none;
          margin-top: -3px;
          margin-left: -15px;
          display: flex;
          flex-direction: column;
          z-index: 2;

          .slide-arrow {
            opacity: 0.5;
            transition: opacity time(default) ease(inout);
            pointer-events: initial;

            &:not(:last-child) {
              margin-bottom: 80px;
            }

            svg path {
              fill: color(blue);
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .section-tour {
    position: relative;
    margin: 70px 0 40px;
    z-index: 2;

    .block {
      position: relative;
      width: 970px;
      height: 260px;
      margin: 0 auto;
      box-shadow: 0px 40px 80px 0px rgba(2, 29, 72, 0.4);

      &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &::after {
          content: "";
          background-image: linear-gradient(
            to right,
            #021d48,
            fade-out(#021d48, 1)
          );
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.8;
          z-index: 5;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 100px;
        width: 100%;
        height: 100%;
        z-index: 2;

        &--icon {
          flex-shrink: 0;
          margin-top: -40px;

          svg path {
            fill: white;
          }
        }

        &--description {
          margin-left: 30px;

          .title {
            color: white;
            font-size: 36px;
            font-weight: 300;
            line-height: 1.3;
            margin-bottom: 25px;

            strong {
              font-weight: 600;
            }
          }

          .subtitle {
            color: white;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  .section-evolution {
    position: relative;
    padding: 40px 0;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 40px 0 60px;
    }

    &.no-cta {
      padding-bottom: 120px;

      /** Mobile. */
      @media (max-width: 1023px) {
        padding-bottom: 60px;
      }
    }

    .headline {
      text-align: center;

      &-title {
        color: color(blue);
        font-size: 36px;
        font-weight: 300;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 24px;
        }
      }
    }

    .status {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 55px;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      .item {
        width: 270px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 50px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin: 0 0 30px;
          }
        }

        &:nth-child(3) {
          width: auto;

          span:nth-child(1) {
            margin-right: 10px;
          }
        }

        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            &:nth-child(1) {
              color: color(grey);
              font-size: 16px;
              font-weight: 600;
            }

            &:nth-child(2) {
              color: #0873ba;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        &-progress {
          background-color: #9ec3dc;
          position: relative;
          width: 100%;
          height: 2px;
          margin-top: 35px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin-top: 5px;
          }

          &--line {
            position: absolute;
            background-color: #0873ba;
            top: 0;
            left: 0;
            height: 100%;
          }
        }
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 65px;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      .left {
        width: 370px;
        flex-shrink: 0;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        > span {
          color: #0873ba;
          font-size: 20px;
          font-weight: bold;
        }

        &-items {
          margin-top: 40px;

          .item {
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 25px;
            }

            &-title {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                &:nth-child(1) {
                  color: color(grey);
                  font-size: 16px;
                  font-weight: 600;
                }

                &:nth-child(2) {
                  color: #0873ba;
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }

            &-progress {
              background-color: #9ec3dc;
              position: relative;
              width: 100%;
              height: 2px;
              margin-top: 7px;

              /** Mobile. */
              @media (max-width: 1023px) {
                margin-top: 5px;
              }

              &--line {
                position: absolute;
                background-color: #0873ba;
                top: 0;
                left: 0;
                height: 100%;
              }
            }
          }
        }
      }

      .right {
        width: 530px;
        flex-shrink: 0;
        margin: 60px 0 0 75px;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }

        &-slide {
          .slide-items {
            .item {
              position: relative;
              width: 530px;
              height: 350px;

              &-image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          .slide-navigation {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            .slide-arrow {
              opacity: 0.4;
              transition-property: opacity;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              svg path {
                fill: color(blue);
              }

              &:hover {
                opacity: 1;
              }
            }

            .slide-count {
              position: relative;
              display: flex;
              align-items: center;
              margin: 0 20px;
              top: -1px;

              span {
                color: color(blue);
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .block {
        position: relative;
        width: 100%;
        height: 160px;
        margin-top: 50px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 4;
        }

        &-background {
          background-color: black;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 1;
          }
        }

        &-content {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          text-align: center;
          z-index: 2;

          &--title {
            color: white;
            font-size: 28px;
            font-weight: 300;

            /** Mobile. */
            @media (max-width: 355px) {
              font-size: 22px;
            }
          }

          &--subtitle {
            color: white;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.4;
            letter-spacing: 4px;
            display: block;
            width: 100%;
            margin-top: 10px;
            padding: 0 20px;
          }
        }
      }
    }
  }

  .section-map {
    position: relative;
    padding: 40px 0;
    z-index: 2;

    .flex-wrapper {
      display: flex;
      box-shadow: 0px 60px 120px 0px rgba(103, 120, 131, 0.4);

      .left {
        background-color: white;
        min-height: 500px;
        flex-grow: 1;
        padding: 40px 15px 60px 95px;

        &-title {
          background-image: linear-gradient(to right, #ea3424, #fec70c);
          font-size: 36px;
          font-weight: 300;
          display: inline-block;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &-address {
          margin-top: 15px;

          p {
            color: color(grey);
            font-size: 18px;
            font-weight: normal;
            line-height: 1.4;
            margin: 0;
            padding: 0;
          }
        }

        &-subtitle {
          color: #0873ba;
          font-size: 18px;
          font-weight: 500;
          margin-top: 35px;
        }

        &-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          width: 330px;
          margin-top: 30px;

          .item {
            width: 100%;
            height: 150px;

            button {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              border: 1px solid #0873ba;
              border-radius: 5px;
              transition-property: background-color, border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              span {
                color: color(grey);
                font-size: 18px;
                font-weight: bold;
                display: inline-block;
                margin-top: 20px;
                transition-property: color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }

            &:hover button,
            &.active button {
              background-color: #e7f0f6;
              border-color: #e7f0f6;

              span {
                color: #0873ba;
              }
            }
          }
        }
      }

      .right {
        width: 580px;
        flex-shrink: 0;

        .map {
          position: relative;
          width: 100%;
          height: calc(100% - 150px);

          &-frame {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            .window-map {
              margin: 0;
              padding: 0;

              &-title {
                color: color(green);
                font-size: 16px;
                font-weight: normal;
                margin: 0 0 5px;
                padding: 0;
              }

              &-description {
                max-width: 230px;
                margin: 0;
                padding: 0;

                p {
                  color: black;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 1.4;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          .no-routes {
            background-color: fade-out(black, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity time(default), visibility time(default);

            span {
              background-color: color(green);
              color: white;
              width: 380px;
              height: 50px;
              text-transform: uppercase;
              letter-spacing: 1.5px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1px 0 0;
            }

            /** Active. */
            &.active {
              opacity: 1;
              visibility: visible;
              pointer-events: initial;
            }
          }
        }

        .search {
          background-color: #1f4574;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          position: relative;
          width: 100%;
          height: 150px;
          padding-left: 45px;

          &-title {
            color: white;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          &-form {
            display: flex;
            margin-top: 25px;

            .field-group {
              width: 335px;
              margin-right: 30px;

              .field {
                background-color: transparent;
                color: #9ec3dc;
                font-size: 16px;
                font-weight: normal;
                padding-bottom: 10px;
                width: 100%;
                display: block;
                border: none;
                border-bottom: 1px solid fade-out(#9ec3dc, 0.7);
                transition-property: border-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                &::placeholder {
                  color: #9ec3dc;
                  font-weight: bold;
                }
              }

              &.invalid .field {
                border-color: red;
              }
            }

            .form-submit {
              button {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  background-image: linear-gradient(to right, #ea3424, #fec70c);
                  font-size: 12px;
                  font-weight: bold;
                  text-transform: uppercase;
                  letter-spacing: 2px;
                  margin-right: 5px;
                  display: inline-block;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }

                svg {
                  transition-property: transform;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);

                  path {
                    fill: white;
                  }
                }

                &:hover svg {
                  transform: translateX(10px);
                }
              }
            }
          }
        }
      }
    }
  }

  .section-cta {
    position: relative;
    padding: 80px 0 120px;

    .background {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      img {
        width: 100%;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      padding: 0 100px;

      &-title {
        background-image: linear-gradient(to right, #ea3424, #fec70c);
        font-size: 48px;
        font-weight: 300;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-bottom: 30px;
      }

      &-description {
        width: 370px;

        p {
          color: color(grey);
          font-size: 18px;
        }
      }

      &-form {
        background-color: fade-out(white, 0.6);
        border: 1px solid #9ec3dc;
        border-radius: 5px;
        width: 430px;
        margin-top: 65px;
        padding: 60px 65px 55px;

        .field-group {
          &:not(:last-child) {
            margin-bottom: 35px;
          }

          .field {
            background-color: transparent;
            color: color(blue);
            font-size: 16px;
            font-weight: normal;
            padding-bottom: 10px;
            width: 100%;
            display: block;
            border: none;
            border-bottom: 1px solid fade-out(color(blue), 0.7);
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: color(blue);
              font-weight: bold;
            }
          }

          &.invalid .field {
            border-color: red;
          }
        }

        .terms-option {
          margin: -15px 0 -20px;

          button {
            background-color: transparent;
            padding-left: 25px;
            border: none;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 1px;
              left: 0;
              width: 12px;
              height: 12px;
              border: 1px solid color(blue);
              transition-property: background-color, border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;
            }

            span {
              color: color(blue);
              font-size: 9px;
              text-align: left;
              line-height: 1.4;
              font-weight: normal;
              display: inline-block;

              a {
                color: color(blue);
                font-weight: bold;
              }
            }
          }

          &.active button::before {
            background-color: color(orange);
            border-color: color(orange);
          }
        }

        .form-submit {
          text-align: center;
          margin-top: 50px;
        }
      }
    }
  }
}
