#main-header {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 50;
  transition-property: top, background-color;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  /** Mobile. */
  @media (max-width: 1199px) {
    position: absolute;
  }
  @media (max-width: 1100px) {
    top: 0;
    padding: 30px 0;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    height: 70px;
    transition-property: box-shadow;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    /** Mobile. */
    @media (max-width: 1100px) {
      justify-content: space-between;
      height: auto;
    }

    .logo {
      position: relative;
      width: 110px;
      height: 110px;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      z-index: 2;

      /** Mobile. */
      @media (max-width: 1100px) {
        width: 80px;
        height: 80px;
      }

      &::before {
        background-image: linear-gradient(to bottom, #fcfdfd, #bdcdda);
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 20px 34.641px 80px 0px rgba(31, 34, 47, 0.2);
        border-radius: 50%;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 1;
      }

      a {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          width: 55px;
          transition-property: width;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          /** Mobile. */
          @media (max-width: 1100px) {
            width: 40px;
          }
        }
      }
    }

    .menu-toggler {
      display: inline-block;

      svg path {
        fill: white;
      }
    }

    .navigation {
      background-color: #d7dde5;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      margin-left: -20px;
      padding-left: 0;
      border-radius: 5px 0 0 5px;
      transition-property: margin-left, padding-left;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
      position: relative;
      z-index: 1;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 80px 0 130px;

        li {
          a {
            color: color(blue);
            font-size: 16px;
            font-weight: bold;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:hover {
              color: color(orange);
            }
          }
        }
      }
    }

    .options {
      flex-shrink: 0;
      display: flex;

      .option {
        // width: 155px;
        width: 205px;
        height: 70px;

        a,
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:hover {
            background-color: color(orange) !important;

            svg path {
              fill: white !important;
            }

            span {
              color: white !important;
            }
          }

          span {
            font-size: 12px;
            font-weight: bold;
            line-height: 1.2;
            margin-left: 10px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }

        &:nth-child(1) {
          button,
          a {
            background-color: white;

            svg path {
              fill: #81949e;
            }

            span {
              color: #81949e;
            }
          }
        }

        &:nth-child(2) {
          button,
          a {
            background-color: #708397;

            svg path {
              fill: white;
            }

            span {
              color: white;
            }
          }
        }

        &:nth-child(3) {
          border-radius: 0 5px 5px 0;
          overflow: hidden;

          button,
          a {
            background-color: color(blue);

            svg path {
              fill: white;
            }

            span {
              color: white;
            }
          }
        }
      }
    }
  }

  /** Desktop. */
  @media (min-width: 1200px) {
    &.sticky {
      top: 0;

      .flex-wrapper {
        box-shadow: 20px 34.641px 80px 0px rgba(31, 34, 47, 0.2);

        .logo {
          &::before {
            opacity: 0;
            transform: scale(0.5);
          }

          a img {
            width: 40px;
          }
        }

        .navigation {
          margin-left: -110px;
          padding-left: 90px;
        }
      }
    }
  }
}
