/** Text content. */
.text-content {
  > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  p {
    font-weight: normal;
    line-height: 1.7;
  }

  ul {
    li {
      position: relative;
      font-weight: normal;
      line-height: 1.7;
      padding-left: 15px;

      &::before {
        content: '';
        background-color: black;
        position: absolute;
        top: 10px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

/** Fancybox. */
.fancybox-content img {
  
  /** Desktop. */
  @media (min-width: 1100px) {
    width: auto;
    height: auto;
    max-width: 89vw;
    max-height: 78vh;
  }
}

/** Transition slide. */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
}
@keyframes fade-out {
  from {
    opacity: 1;
  } 
  to {
    opacity: 0;
  }
}
.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
}

/** Cta mobile. */
.cta-mobile {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;

  /** Desktop. */
  @media (min-width: 1100px) {
    display: none;
  }

  &--item {
    position: relative;
    min-width: 33.33%;
    flex-grow: 1;
    flex-shrink: 0;
    height: 60px;

    a,
    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      span {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: relative;
        bottom: -2px;
        margin-left: 10px;

        /** Mobile. */
        @media (max-width: 355px) {
          margin-left: 5px;
        }
      }
    }
  
    &.item-phone {
      background-color: white;

      svg path {
        fill: color(green);
      }

      span {
        color: black;
      }
    }

    &.item-chat {
      background-color: #708397;

      svg {
        position: relative;
        top: 1px;

        path {
          fill: white;
        }
      }

      span {
        color: white;
      }
    }
    
    &.item-wpp {
      background-color: color(blue);

      svg path {
        fill: white;
      }

      span {
        color: white;
      }
    }
  }
}