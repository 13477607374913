#main-cookies {
  background-color: color(blue);
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 410px;
  z-index: 45;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  /** Mobile. */
  @media (max-width: 767px) {
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: auto;
  }

  &.remove {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .content {
    padding: 30px;

    /** Mobile. */
    @media (max-width: 767px) {
      padding: 20px 25px;
    }

    &-title {
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &-description {
      color: white;
    }

    &-cta {
      display: flex;
      align-items: center;
      margin-top: 30px;

      /** Mobile. */
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      button,
      a {
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 40px;
        padding: 2px 30px 0;
        border: 1px solid white;
        border-radius: 20px;
        transition-property: border-color, background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-right: 20px;

          /** Mobile. */
          @media (max-width: 767px) {
            margin: 0 0 20px;
          }
        }

        &:hover {
          background-color: color(orange);
          border-color: color(orange);
        }
      }
    }
  }
}
