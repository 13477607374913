.modal-ligamos {
  .background {
    background-color: #0B213C;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    &::after {
      content: '';
      background-image: linear-gradient(to right, #003373, fade-out(#003373, 1));
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .3;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .close {
    position: absolute;
    top: 70px;
    right: 10px;
    z-index: 3;

    svg path {
      fill: white;
    }
  }

  .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .content {
      width: 310px;
      flex-shrink: 0;

      &-title {
        background-image: linear-gradient(to right, #EA3424, #FEC70C);
        font-size: 48px;
        font-weight: 300;
        line-height: 1.4;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }

      &-description {
        margin-top: 40px;

        p {
          color: white;
        }
      }
    }

    .form {
      border: 1px solid white;
      border-radius: 10px;
      margin-left: 150px;
      width: 410px;
      padding: 60px 30px 40px;

      &-row,
      &-flex {
        margin-bottom: 40px;
      }

      &-flex {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
      }

      &-submit {
        text-align: right;
        margin-top: 35px;
        padding-right: 20px;
      }

      .field-group {
        position: relative;
        width: 100%;

        .field {
          background-color: transparent;
          color: white;
          font-size: 15px;
          font-weight: normal;
          padding-bottom: 10px;
          display: block;
          width: 100%;
          border: none;
          border-bottom: 1px solid fade-out(white, .7);
          transition-property: border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &::placeholder {
            color: white;
            font-weight: bold;
          }
        }

        .field[type="date"]::-webkit-inner-spin-button,
        .field[type="date"]::-webkit-calendar-picker-indicator,
        .field[type="time"]::-webkit-inner-spin-button,
        .field[type="time"]::-webkit-calendar-picker-indicator {
          display: none;
          -webkit-appearance: none;
        }

        svg {
          position: absolute;
          top: 8px;
          transform: translateY(-50%);
          right: 3px;
          opacity: .5;
          z-index: 5;
          pointer-events: none;

          path {
            fill: white;
          }
        }

        &.invalid .field {
          border-color: red;
        }
      }
    }
  }
}